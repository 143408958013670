
export const headMeta = {
  data () {
    return {
      //pageURL: 'http://remixer.visualthinkery.com/' + this.$route.path,
      //imageURL: 'http://remixer.visualthinkery.com/' + this.appSettings.image,
      baseURL: process.env.baseUrl,
      // host: req ? req.headers.host : window.location.host.split(':')[0]
    }
  },

  head () {
    if (this.appSettings && ('templateID' in this.appSettings)){
      // this page is an app
      var metaDetails = {};
      metaDetails.title = this.appSettings.title;
      const imageRes = (this.appSettings.highRes)? '_1000x1000' : '_500x500';

      if (this.remix && ('thumbnail' in this.remix)){
        // this is a remix
        metaDetails.description = 'Remixed by ' + this.remix.creator.name;
        //metaDetails.image = this.remix.thumbnail
        const updated = (this.remix.updated) ? this.remix.updated : '0';
        metaDetails.image = this.$globalvars.rootStorageURL + this.$globalvars.thumbCollection + '%2F' 
            + this.remix.id + imageRes + '.jpeg?alt=media&u=' + updated;

      }else{
        // this is a Master template
        metaDetails.description = this.appSettings.description;
        metaDetails.image = this.appSettings.image
      }
    }else{
      // this page is a normal page
      var metaDetails = {
        title: "The Fabulous Remixer Machine",
        description: 'Anyone can remix!',
        image: process.env.baseUrl + "/thumbs/remixer_card2.png",
      }
    }
    return {
      title: metaDetails.title,
      meta: [
        { hid: 'description', name: 'description', content: metaDetails.description },
        // OpenGraph data (Most widely used)
        {hid:'og:title', property: 'og:title', content: metaDetails.title + ' by Visual Thinkery'},
        {hid:'og:site_name', property: 'og:site_name', content: 'Visual Thinkery'},
        // The list of types is available here: http://ogp.me/#types
        {hid:'og:type', property: 'og:type', content: 'website'},
        // Should the the same as your canonical link, see below.
        {hid:'og:url', property: 'og:url', content: this.baseURL + this.$route.path},
        {hid:'og:image', property: 'og:image', content: metaDetails.image},
        // Often the same as your meta description, but not always.
        {hid:'og:description', property: 'og:description', content: metaDetails.description},

        // Twitter card
        {hid:'twitter:card', name: 'twitter:card', content: 'summary_large_image'},
        {hid:'twitter:site', name: 'twitter:site', content: this.baseURL + this.$route.path},
        {hid:'twitter:creator', name: 'twitter:creator', content: '@visualthinkery'},
        {hid:'twitter:title', name: 'twitter:title', content: metaDetails.title + ' by Visual Thinkery'},
        {hid:'twitter:description', name: 'twitter:description', content: metaDetails.description},

        // Your twitter handle, if you have one.
        {hid:'twitter:creator', name: 'twitter:creator', content: '@visualthinkery'},
        {hid:'twitter:image:src', name: 'twitter:image:src', content: metaDetails.image},

        // Google / Schema.org markup:
        {hid:'g:name', itemprop: 'name', content: metaDetails.title + ' by Visual Thinkery'},
        {hid:'g:description', itemprop: 'description', content: metaDetails.description},
        {hid:'g:image', itemprop: 'image', content: metaDetails.image},
      ]
    }
  },


}
