<template>
<div id="appCards">
  <app-header></app-header>
  <div class="container pt-4">
    <div  class="p-0 mt-4">


      <!-- <h3>Anyone can Remix!</h3> -->
      <div class="row mb-5 ">
        <div class="col-md-7 col-xs">
          <h4>Welcome to the Fabulous Remixer Machine!</h4>
          <p class="text-muted">
            The Remixer Machine is the perfect collaborative activity for creative people.
            Create a remix from someone else's remix, or choose a template below and get making. <br/><br/>
            <!-- All creations are usable under a Creative Commons licence.<br/><br/> -->
            <!-- If you'd like to help improve remixer, please send us your <nuxt-link :to="{path: '/', hash: 'footer'}">feedback and ideas</nuxt-link>. :) -->
            
            <b-embed
            type="iframe"
            aspect="16by9"
            src="https://www.youtube.com/embed/ZIIAzIE1t3k"
            allowfullscreen
          ></b-embed>
          <!-- <b-badge class="mr-1" variant="info"><span style="font-size:1.2em;font-weight:800">COMING SOON</span></b-badge> -->


          </p>
        </div>
        <div class="col px-5" >
          <!-- Back this on Patreon -->
          <!-- <div class="mt-2 mb-2 d-flex align-items-center">
            <div class="pr-2"><a href="https://patreon.com/remixer" target="_blank"><font-awesome-icon style="font-size:3em" fixed-width  :icon="['fab', 'patreon']" /></a></div>
            <div><a href="https://patreon.com/remixer" target="_blank">Become a patron of the Remixer Machine!</a></div>
          </div> -->
          <p class="pt-4"><a href="https://www.patreon.com/remixer"><img src="/become_a_member.png" width="100%" class="" alt="Become a member"/></a></p>
          <p class="pt-2 text-muted">Members of the Remixer Machine Community can now create <nuxt-link to="/galleries">Private Galleries</nuxt-link>, which come with the following remix superpowers:</p>
            <ul><li><b>Customise</b> the template remix</li>
            <li><b>Share</b> an unlisted link with your own community of remixers</li>
            <li><b>Remove</b> any unwanted remixes.</li>
          </ul>
          <!-- <p class="mt-4">
            <nuxt-link to="/galleries"><b-img style="max-width:75%" center class="img-fluid hue-rotate" src="/chameleon_galleries.png" title="Private Galleries this way..."/></nuxt-link>
          </p> -->

            
        </div>
      </div>

       

      <div class="full-width-image" style="background-color: #11111166;">
        <div class="container">
            <div class="row">

              <!-- featured -->
              <div v-for="(featuredObj,key) in $globalvars.featured" :key="featuredObj.title">
                <div  class="col pt-4 mb-4">
                  <h3 class="remixer-subheader">#{{featuredObj.title}}</h3>
                  <p>{{featuredObj.description}}</p>
                  <div class="card-columns">
                    <div v-for="(remixObj) in featuredObj.remixes" :key="remixObj.remix.id">
                      <div class="card bg-dark mb-3">
                        <nuxt-link  :to="{name: 'r-rid-gid', params: { rid:remixObj.remix.id }}"><img class="card-img-top" :src="remixObj.remix.thumbnail" alt="Remix this!"></nuxt-link>
                        <div class="card-body">
                          <h5 class="card-title"><nuxt-link :to="{name: 'r-rid-gid', params: { rid:remixObj.remix.id }}">{{remixObj.title}}</nuxt-link></h5>
                          <p class="card-text">{{remixObj.comment}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col py-4 my-4">
                <h3 class="remixer-subheader">latest remixes</h3>
                <remix-snapshots class="full-width" :limit="5"  noPaginate></remix-snapshots>
                <div class="pt-2 pb-2" style="text-align:right"><nuxt-link :to="{name: 'remixes'}">All the Remixes >></nuxt-link></div>
              </div>
            </div>
        </div>
      </div>

      <!-- <h1 class="mt-3">#CCSummit - <span class="badge badge-pill badge-info">Live</span></h1>
      <div class="row advertRow rounded p-3 m-0 mb-4">
        <div class="col">
          <div class="d-flex align-items-center flex-wrap">
            <div class="">
              Remix a stamp and stick it in the #ccsummit Stamp Collector's Book!
            </div>
            <div class="ml-auto" style="text-align:right"><nuxt-link to="/a/ccstamp/gallery"><button class="btn btn-primary btn-sm">Stamp Collector's Book &gt;</button></nuxt-link></div>
          </div>
          <div class="">
            <remix-snapshots class="full-width" filterProperty="templateID" filterOperator="==" filterValue="ccstamp" :limit="5" flexRow noPaginate></remix-snapshots>
          </div>

        </div>
      </div> -->

     

      

      <div v-for="(hashtag,key) in hashtags" :key="key">
        <div  class="pt-4 mb-4">
          <h1 class="remixer-subheader">#{{key}}</h1>
          <div class="card-columns half-width">
            <div v-for="templateID in hashtag" :key="templateID">
              <panel :appInstanceID="templateID"></panel>
            </div>
          </div>
        </div>
      </div>
      

    </div>

  </div>
  <app-footer id="footer"></app-footer>
</div>
</template>

<script>

/*
TODO
<router-link to="app.path">app.name</router-link>

*/
import {headMeta} from '@/components/mixins/headMeta'
export default {
  name: 'Home',
  props: {
    title: String,
  },
  mixins: [headMeta],
  components: {
  },
  data () {
    return {
      things: {
        meta : {
          title: "Remixer",
          description: 'Anyone can remix!',
          image: process.env.baseUrl + "/thumbs/remixer_card.png",
        }
      },
      //cards: this.$globalvars.templates,
      hashtags: {},

    }
  },
  methods: {
    /*
    async updateStorageFilesToJPG(){
      console.log(this.$globalvars.thumbCollection)
      var listRef = this.$fire.storage.ref().child(this.$globalvars.thumbCollection);
      console.log(listRef)

      // var firstPage = await listRef.list({ maxResults: 10});
      // console.log(firstPage.items)

      //Find all the prefixes and items.
      listRef.listAll()
        .then((res) => {
          // res.prefixes.forEach((folderRef) => {
          //   // All the prefixes under listRef.
          //   // You may call listAll() recursively on them.
            
          // });
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            console.log(itemRef)
          });
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });
    }
    */

  },
  created: function(){

    // Sort the Apps into Hashtag categories

    //console.log(this.cards)
    for (var i in this.$globalvars.templates){
      //console.log(this.cards[i].title)
      if (this.$globalvars.templates[i].public){

        // create a this.hashtags object
        for (var j=0; j<this.$globalvars.templates[i].hashtags.length; j++){

          // if hashtag doesnt exist - then add an entry for it
          if (!this.hashtags[this.$globalvars.templates[i].hashtags[j]]){
            this.$set(this.hashtags,this.$globalvars.templates[i].hashtags[j],[]);
          }
          this.hashtags[this.$globalvars.templates[i].hashtags[j]].push(i);

        }

      }

    }



  },
  mounted: function(){
    //console.log(this.$route);
    //console.log('baseUrl',process.env.baseUrl);
    //this.updateStorageFilesToJPG()
  }
}

</script>


<style lang="css" scoped>
  /* .advertRow {
    background:transparent url('~assets/ccstamp/postmark_background.jpg') no-repeat center center /cover;

  } */


.remix-grid {
  display: grid;
  box-sizing: border-box;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr)); */
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: center;
  align-items: center;

}

.hue-rotate {
  animation: filter-animation 32s infinite;
  -webkit-animation: filter-animation 32s infinite;
}

@keyframes filter-animation {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(-180deg);
  }

  100% {
    filter: hue-rotate(0deg);
  }
}

@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }

  50% {
    -webkit-filter: hue-rotate(-180deg);
  }

  100% {
    -webkit-filter: hue-rotate(0deg);
  }
}



</style>
